
import footerbanner from "../assets/images/w-f.png";

const Footer = () => {
  return (
    <div className='sticky top-[100vh] border-0 bg-[#242729]'>
        <div className=" relative w-full text-center justify-center">
        <img src={footerbanner} className=" w-full h-40" alt="" />
       
      </div>
        <div className='py-2 text-center'>
          <div className='text-center text-[#fff] '>
          If you have difficulties accessing any of the links or if you have questions/clarifications, please contact <span class="underline">mtcithelpdesk@matimco.com</span>.
          </div>
          <div className='text-center text-[#fff] '>
             All Rights Reserved © 2023
          </div>
        </div>
    </div>
  );
};

export default Footer;
