import { useState } from 'react';
import { Link } from 'react-router-dom';
import mtcLogo from '../assets/images/matimco-logo-top.png'

// import companyLogo from '../assets/images/logo.svg';

const Navbar = () => {
  const [toggleMenu, setToggleMenu] = useState(false);

  return (
    <>


    <div className=' w-full border-b-4 bg-[#242729] border-0'>
    <nav className='relative container mx-auto'>
      {/* Flex Container */}
      <div className='flex items-center justify-between'>
        {/* Logo */}

        <div className='p-2'>
        <img src={ mtcLogo} className=" w-14 h-14" alt="" />
          <span className=" absolute top-5 left-20 font-normal text-2xl text-white">Matimco One Portal</span>
          {/* <h2 className="text-2xl font-extrabold text-white">Business Systems</h2> */}
          {/* <h2 className="text-4xl font-bold text-center text-red-300">MATIMCO SOFTWARE</h2> */}
          {/* <img src={companyLogo} alt='' /> */}
        </div>
        {/* Menu Items */}
        <div className='hidden text-2xl md:flex'>
          {/* <Link to='#' className=' text-white text- hover:text-gray-300'>About Us</Link> */}
          <Link to='https://apps.matimco.com:8084' className=' text-white hover:text-gray-300'>
            Contact Us
          </Link>
        </div>
        {/* Button */}
        {/* <Link
          to='#'
          className='hidden p-3 px-6 pt-2 text-white bg-orange-500  rounded-full baseline hover:bg-brightRedLight md:block'serce
        >
          Get Started
        </Link> */}

        {/* Hamburger Icon */}
        {/* <button
          className={
            toggleMenu
              ? 'open block hamburger md:hidden focus:outline-none'
              : 'block hamburger md:hidden focus:outline-none'
          }
          onClick={() => setToggleMenu(!toggleMenu)}
        >
          <span className='hamburger-top'></span>
          <span className='hamburger-middle'></span>
          <span className='hamburger-bottom'></span>
        </button> */}
      </div>
  

      {/* Mobile Menu */}
      {/* <div className='md:hidden'>
        <div
          className={
            toggleMenu
              ? 'absolute flex flex-col items-center self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
              : 'absolute flex-col items-center hidden self-end py-8 mt-10 space-y-6 font-bold bg-white sm:w-auto sm:self-center left-6 right-6 drop-shadow-md'
          }
        >
          <Link to='#'>Pricing</Link>
          <Link to='#'>Product</Link>
          <Link to='#'>About Us</Link>
          <Link to='#'>Careers</Link>
          <Link to='#'>Community</Link>
        </div>
      </div> */}
    </nav>
    </div>
    </>
  );
};

export default Navbar;
