import React, { useEffect, useState } from "react";
import epod from "../assets/images/tms.png";
import billez from "../assets/images/billez.png";
import dcs from "../assets/images/dcs.png";
import hris from "../assets/images/hris.png";
import fiori from "../assets/images/fiori.png";
import arms from "../assets/images/arms.png";
import ictticketing from "../assets/images/ictticketing.png";
import rofa from "../assets/images/rofa.png";
import welcomebanner from "../assets/images/w-n.png";
import footerbanner from "../assets/images/w-f.png";
import wavingHand from "../assets/images/waving hand.gif";
import woodbg from "../assets/images/wood-bg.png";
import bio from "../assets/images/bio.png";
import webreport from "../assets/images/webreport.png";
import lutosnote from "../assets/images/lutosnotes.png";
import qms from "../assets/images/qms.png";
import citrix from "../assets/images/citrix.png";
import armsv2 from "../assets/images/armslogov2.png";
import ajsr from "../assets/images/ajsr.png";
let options = [
  {
    label: "HRIS",
    value: "01",
    decription: "Human Resource Information System",
    link: "https://apps.matimco.com:8082/HRISLIVE",
    locallink: "https://apps.matimco.com:8082/HRISLIVE",
    category: "Support",
    banner: "",
  },
  {
    label: "ARMS 2.0",
    values: "14",
    decription: "Account Resource Management System",
    link: "https://apps.matimco.com:8085",
    locallink: "https://apps.matimco.com:8085",
    category: "Value Change",
    banner: "",
  },
  {
    label: "ARMS",
    values: "06",
    decription: "Account Resource Management System",
    link: "https://apps.matimco.com:8082/ARMS",
    locallink: "https://apps.matimco.com:8082/ARMS",
    category: "Legacy",
    banner: "",
  },
  {
    label: "SAP",
    values: "09",
    decription: "",
    link: "https://sapfiori.matimco.com:8083/fiori?sap-client=888&sap-language=EN#Shell-home",
    locallink:
      "https://sapfiori.matimco.com:8083/fiori?sap-client=888&sap-language=EN#Shell-home",
    category: "Value Change",
    banner: "",
  },
  {
    label: "ePOD",
    values: "03",
    decription: "Electronic Proof Of Delivery",
    link: "https://apps.matimco.com:81/epod?usertype=traffic",
    locallink: "https://apps.matimco.com:81/epod?usertype=traffic",
    category: "Value Change",
    banner: "",
  },
  {
    label: "Bill EZ",
    values: "02",
    decription: "",
    link: "https://apps.matimco.com:81/epod?usertype=finance",
    locallink: "https://apps.matimco.com:81/epod?usertype=finance",
    category: "Value Change",
    banner: "",
  },

  {
    label: "DRF",
    values: "04",
    decription: "Design Request Form",
    link: "https://apps.matimco.com/dcs/Login",
    locallink: "https://apps.matimco.com/dcs/Login",
    category: "Support",
    banner: "",
  },
  {
    label: "ROFA",
    values: "05",
    decription: "Revolving Operating Fund Account",
    link: "https://apps.matimco.com/ROFA",
    locallink: "https://apps.matimco.com/ROFA",
    category: "Support",
    banner: "",
  },

  {
    label: "Web Reports",
    decription: "",
    values: "07",
    link: "http://192.168.20.43/reports",
    locallink: "http://192.168.20.43/reports",
    category: "Support",
    banner: "",
  },
  {
    label: "ICT Service Desk X",
    values: "08",
    decription: "",
    link: "https://apps.matimco.com:8084",
    locallink: "https://apps.matimco.com:8084",
    category: "Support",
    banner: "",
  },

  {
    label: "BIOS",
    values: "10",
    decription: "Biometrics Integrated Operations System",
    link: "https://apps.matimco.com:8083/biometrics",
    locallink: "https://apps.matimco.com:8083/biometrics",
    category: "Value Change",
    banner: "",
  },
  {
    label: "Cebu LN iNotes",
    values: "11",
    decription: "Cebu Lotus Notes iNotes",
    link: "http://cebumailserver.matimco.com:8088/",
    locallink: "http://cebumailserver.matimco.com:8088/",
    category: "Legacy",
    banner: "",
  },
  {
    label: "Caloocan LN iNotes",
    values: "12",
    decription: "Caloocan Lotus Notes iNotes",
    link: "http://qc-mailserver.matimco.com:8088/",
    locallink: "http://qc-mailserver.matimco.com:8088/",
    category: "Legacy",
    banner: "",
  },
  {
    label: "QMS Library",
    values: "13",
    decription: "Quality Management System Library",
    link: "https://sites.google.com/matimco.com/matimco-qms-library/quality-policy-and-objectives?authuser=0",
    locallink: "https://sites.google.com/matimco.com/matimco-qms-library/quality-policy-and-objectives?authuser=0",
    category: "Support",
    banner: "",
  },
  // {
  //   label: "Citrix",
  //   values: "13",
  //   decription: "Citrix XenApp",
  //   link: "http://122.53.100.212/Citrix/XenApp/auth/login.aspx?CTX_MessageType=WARNING&CTX_MessageKey=NoUsableClientDetected",
  //   locallink: "http://122.53.100.212/Citrix/XenApp/auth/login.aspx?CTX_MessageType=WARNING&CTX_MessageKey=NoUsableClientDetected",
  //   category: "Legacy",
  //   banner: "",
  // },
  {
    label: "AJSR 2.0",
    values: "14",
    decription: "Admin Job Service Request",
    link: "https://www.appsheet.com/start/299b9948-86d3-480d-807f-3e801bab7e8c",
    locallink: "https://www.appsheet.com/start/299b9948-86d3-480d-807f-3e801bab7e8c",
    category: "Support",
    banner: "",
  },
];

const Content = () => {
  const [data, setData] = useState([]);

  let [count, setCount] = useState(0);
  // const[selectedSoftware,setSelectedSoftware]=useState([]);

  const getLogo = (label) => {
    switch (label) {
      case "ePOD":
        return epod;
      case "Bill EZ":
        return billez;
      case "DRF":
        return dcs;
      case "HRIS":
        return hris;
      case "SAP":
        return fiori;
      case "ARMS":
        return arms;
        case "ARMS 2.0":
          return armsv2;
        
      case "ICT Service Desk X":
        return ictticketing;
      case "ROFA":
        return rofa;
      case "BIOS":
        return bio;
      case "Web Reports":
        return webreport;
      case "Cebu LN iNotes":
        return lutosnote;
      case "Caloocan LN iNotes":
        return lutosnote;
        case "QMS Library":
          return qms;
          case "AJSR 2.0":
            return ajsr;
          // case "Citrix":
          //   return citrix;
      default:
        return epod;
    }
  };

  const AllHandler = (f) => {
    var v = options;
    setData(v);
  };
  const ValueChangeHandler = (f) => {
    var v = options.filter((a) => a.category == f);
    setData(v);
  };
  const SupportHandler = (f) => {
    var v = options.filter((a) => a.category == f);
    setData(v);
  };

  useEffect(() => {
    setData(options);
  }, [count]);

  return (
    <>
      <div className=" relative w-full text-center justify-center -top-1">
        <img src={welcomebanner} className=" w-full h-40" alt="" />
        <span className=" text-4xl font-bold relative -top-24">
          {/* Welcome back Steward! 👋 */}
          Welcome back Steward!
          <img
            src={wavingHand}
            className=" absolute inset-y-0 right-0 w-32 h-32 -mr-32 -top-12"
            alt=""
          />
        </span>
      </div>
      <div className="relative w-full px-5 -mt-16 text-center xl:space-x-8 text-xl">
        <button
          className="py-2 px-6 bg-transparent border border-[#000] rounded-lg focus:bg-[#7F1D1D] focus:text-white hover:text-white hover:bg-[#7F1D1D] hover:bg-opacity-75"
          onClickCapture={() => AllHandler("All")}
        >
          All
        </button>
        <button
          className="py-2 px-6 bg-transparent border border-[#000] rounded-lg focus:bg-[#7F1D1D] focus:text-white hover:text-white hover:bg-[#7F1D1D] hover:bg-opacity-75"
          onClickCapture={() => ValueChangeHandler("Value Change")}
        >
          Value Chain
        </button>
        <button
          className="py-2 px-6 bg-transparent border border-[#000] rounded-lg focus:bg-[#7F1D1D] focus:text-white hover:text-white hover:bg-[#7F1D1D] hover:bg-opacity-75"
          onClickCapture={() => SupportHandler("Support")}
        >
          Support
        </button>
        <button
          className="py-2 px-6 bg-transparent border border-[#000] rounded-lg focus:bg-[#7F1D1D] focus:text-white hover:text-white hover:bg-[#7F1D1D] hover:bg-opacity-75"
          onClickCapture={() => SupportHandler("Legacy")}
        >
          Legacy
        </button>
      </div>

      <div className="grid grid-cols-1 items-center grid-rows-1 xl:grid-cols-5 mt-10 xl:mx-44 sm:mx-64">
        {data.map((a) => (
          <div className="group relative mr-1 items-center space-y-2 border-black border bg-white rounded-xl mt-1 xs:mt-6 mb-8 m-4 hover:shadow-[0_5px_20px_0_rgba(0,0,0,0.8)] hover:border-black focus:shadow-[0_5px_20px_0_rgba(0,0,0,0.8)]">
            <a
              className="group relative mr-1 items-center space-y-2"
              href={a.link}
              target="_blank"
              rel="noopener noreferrer"
            >
              <div
                className={`items-center justify-center w-auto h-auto px-4 pt-1 bg-transparent`}
              >
                <img
                  src={getLogo(a.label)}
                  className=" w-auto h-auto rounded-tr-xl rounded-tl-xl"
                  alt=""
                />
              </div>
              <div className="border-t border-black w-full bg-transparent rounded-br-xl rounded-bl-xl h-20 p-4 border-t-25 ">
                <h5 className="text-2xl font-extrabold ">{a.label}</h5>
                <span className=" text-xs">{a.decription}</span>
              </div>
            </a>
          </div>
        ))}
      </div>
    </>
  );
};

export default Content;
