
import { BrowserRouter } from 'react-router-dom';
import Navbar from './components/Navbar';
import Content from './components/Content';
 import Footer from './components/Footer';

function App() {
  return (
    <BrowserRouter>
    <div className='min-h-screen'>
      <Navbar />
      <Content />
      <Footer />
      </div>
    </BrowserRouter>
  );
}

export default App;
